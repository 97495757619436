a {
  color: black !important
}

a:hover {
  text-decoration: none;
}

.App {
  background-color: #8b3d96;
  background-image: url("http://masterclass.hookfinland.fi/img/bg.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
}

.Main {
  padding-top: 5vh;
}

.EpContainer {
  margin-top: 40px;
  padding: 20px;
  border-radius: 10px;
  background-color: rgba(255,255,255,0.3);
}

.BackToTop {
  width: 95%;
  text-align: right;
  position: absolute;
  bottom: 0;
}

.Footer {
  padding: 5vh;
  text-align: center;
}

.jurek {
  width: 100%;
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}